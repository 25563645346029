import React from 'react';
import { Row, Col, Container, Jumbotron, Image, Navbar, Nav } from 'react-bootstrap';
import { zustandStore } from '../store/states';
import StartInterface from '../components/StartInterface';
import VragenInterface from '../components/VragenInterface';
import ResultaatInterface from '../components/ResultaatInterface';
import logo from '../assets/images/logo.png';
import welkom_header from '../assets/images/welkom_header.jpg';
import vraag_header from '../assets/images/vraag_header.jpg';

const LoonwaardeKiezer = () => {

    //result states
    const result = zustandStore(state => state.result)
    const start = zustandStore(state => state.start)
    const calcResult = zustandStore(state => state.calcResultState)

    return (
        <div className="interface">
        <Row className="content">
            <Col xs={12}>
                <Jumbotron id="header" 
                style={ start || result ? { backgroundImage:`url(${welkom_header})`} : { backgroundImage:`url(${vraag_header})`}}>
                    <Container>
                        <Row>
                            <Col className="d-flex align-items-center">
                                <Image className="d-inline app-logo" src={logo} />
                                <h1 className="d-inline app-title" > | LoonwaardeKiezer</h1>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                <Jumbotron id="header__pattern">
                </Jumbotron>
            </Col>
            <Col xs={12}>
                <Container>
                    <Jumbotron>
                        {start === true ? (
                            <StartInterface />
                        ) : (
                            <div>
                            {result === true ? (<ResultaatInterface />):
                            (<VragenInterface calcResult={calcResult} />
                            )}
                            </div>
                        )}
                    </Jumbotron>
                </Container>
            </Col>
        </Row>
        <Navbar bg="light" className="footer">
                <Container><Nav.Link href="http://www.dariuz.nl"  className="footer__text">© 2021 Dariuz. De slimme kracht achter duurzame inclusiviteit | www.dariuz.nl</Nav.Link></Container>
            </Navbar>
        </div>
    )
};

export default LoonwaardeKiezer;