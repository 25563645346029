import create from 'zustand'

export const zustandStore = create((set, get) => ({
    //interface vars
    step: 1,
    progress: 0,
    start: true,
    result: false,
    next2: false,
    next3: false,
    nextResult: false,
    next2Popup: false,
    next3Popup: false,
    nextResultPopup: false,

    //toelichting
    toelichtingInclusief: false,
    toelichtingPluz: false,
    toelichtingUniform: false,

    //loonwaarde vars
    uniformeMethodiek: true,
    caoTool: true,
    berekenenLKS: false,
    moeilijkKwantificeerbareTaken: false,
    verklaringEnVerantwoordingCompetenties: false,
    adviesReintegratie: false,
    adviesJobCoachProtocol: false,
    duurzaamheidMonitoringEnAdvies: false,

    //option vars
    q1A: false,
    q1B: false,
    q1C: false,
    q1D: false,

    q2A: false,
    q2B: false,

    q3A: false,
    q3B: false,
    q3C: false,
    q3D: false,
    q3E: false,


    //pakketten vars
    uniform: true,
    pluz: false,
    inclusief: false,

    //toelichting functions
    toelichtingInclusiefState: () => set(state => ({ toelichtingInclusief: !state.toelichtingInclusief })),
    toelichtingPluzState:  () => set(state => ({ toelichtingPluz: !state.toelichtingPluz })),
    toelichtingUniformState:  () => set(state => ({ toelichtingUniform: !state.toelichtingUniform })),
    //question functions

    startKiezer: () => set({ start: false }),
    addQ1A: () => {
        set(state => ({ q1A: !state.q1A }));
        const q1A = get().q1A;
        const q1B = get().q1B;
        const q1C = get().q1C;
        const q1D = get().q1D;
        if (q1A === true || q1B === true) {
            set({ berekenenLKS: true })
            const berekenenLKS = get().berekenenLKS
            console.log("LKS is " + berekenenLKS)
        }
        else {
            set({ berekenenLKS: false })
            const berekenenLKS = get().berekenenLKS
            console.log("LKS is " + berekenenLKS)
        }
        console.log("q1A is " + q1A)

        if (q1A === true || q1B === true || q1C === true || q1D === true) {
            set({ next2: true })
        }
        else {
            set({ next2: false })
        }
    },

    addQ1B: () => {
        set(state => ({ q1B: !state.q1B }));
        const q1A = get().q1A;
        const q1B = get().q1B;
        const q1C = get().q1C;
        const q1D = get().q1D;
        if (q1A === true || q1B === true) {
            set({ berekenenLKS: true })
            const berekenenLKS = get().berekenenLKS
            console.log("LKS is " + berekenenLKS)
        }
        else {
            set({ berekenenLKS: false })
            const berekenenLKS = get().berekenenLKS
            console.log("LKS is " + berekenenLKS)
        }
        console.log("q1B is " + q1B)
        if (q1A === true || q1B === true || q1C === true || q1D === true) {
            set({ next2: true })
        }
        else {
            set({ next2: false })
        }
    },

    addQ1C: () => {
        set(state => ({ q1C: !state.q1C }));
        const q1A = get().q1A;
        const q1B = get().q1B;
        const q1C = get().q1C;
        const q1D = get().q1D;
        if (q1C === true || q1D === true) {
            set({ pluz: true })
            set({ uniform: false })
            const pluz = get().pluz
            console.log("pluz is " + pluz)
        }
        else {
            set({ pluz: false })
            const pluz = get().pluz
            console.log("pluz is " + pluz)
        }
        console.log("q1C is " + q1C)
        if (q1A === true || q1B === true || q1C === true || q1D === true) {
            set({ next2: true })
        }
        else {
            set({ next2: false })
        }
    },

    addQ1D: () => {
        set(state => ({ q1D: !state.q1D }));
        const q1A = get().q1A;
        const q1B = get().q1B;
        const q1C = get().q1C;
        const q1D = get().q1D;
        if (q1C === true || q1D === true) {
            set({ pluz: true })
            set({ uniform: false })
            const pluz = get().pluz
            console.log("pluz is " + pluz)
        }
        else {
            set({ pluz: false })
            const pluz = get().pluz
            console.log("pluz is " + pluz)
        }
        console.log("q1D is " + q1D)
        if (q1A === true || q1B === true || q1C === true || q1D === true) {
            set({ next2: true })
        }
        else {
            set({ next2: false })
        }
    },

    addQ2A: () => {
        set({ q2A: true })
        set({ q2B: false })
        set(state => ({ moeilijkKwantificeerbareTaken: state.q2A }))
        const q2A = get().q2A;
        const moeilijkKwantificeerbareTaken = get().moeilijkKwantificeerbareTaken;
        console.log("q2A is " + q2A)
        console.log("moeilijkKwantificeerbareTaken is " + moeilijkKwantificeerbareTaken)
        set({ next3: true })
    },
    addQ2B: () => {
        set({ q2B: true })
        set({ q2A: false })
        set(state => ({ moeilijkKwantificeerbareTaken: state.q2A }))
        const q2B = get().q2B;
        console.log("q2B is " + q2B)
        set({ next3: true })
    },

    addQ3A: () => {
        set(state => ({ q3A: !state.q3A }));
        set(state => ({ verklaringEnVerantwoordingCompetenties: state.q3A }))
        const q3A = get().q3A;
        const q3B = get().q3B;
        const q3C = get().q3C;
        const q3D = get().q3D;
        const q3E = get().q3E;
        const verklaringEnVerantwoordingCompetenties = get().verklaringEnVerantwoordingCompetenties;
        console.log("q3A is " + q3A)
        console.log("verklaringEnVerantwoordingCompetenties is " + verklaringEnVerantwoordingCompetenties)
        if (q3A === true || q3B === true || q3C === true || q3D === true || q3E) {
            set({ nextResult: true })
        }
        else {
            set({ nextResult: false })
        }
    },
    addQ3B: () => {
        set(state => ({ q3B: !state.q3B }));
        set(state => ({ adviesReintegratie: state.q3B }))
        const q3A = get().q3A;
        const q3B = get().q3B;
        const q3C = get().q3C;
        const q3D = get().q3D;
        const q3E = get().q3E;
        const adviesReintegratie = get().adviesReintegratie;
        console.log("q3B is " + q3B)
        console.log("adviesReintegratie is " + adviesReintegratie)
        if (q3A === true || q3B === true || q3C === true || q3D === true || q3E) {
            set({ nextResult: true })
        }
        else {
            set({ nextResult: false })
        }
    },
    addQ3C: () => {
        set(state => ({ q3C: !state.q3C }));
        set(state => ({ adviesJobCoachProtocol: state.q3C }))
        const q3A = get().q3A;
        const q3B = get().q3B;
        const q3C = get().q3C;
        const q3D = get().q3D;
        const q3E = get().q3E;
        const adviesJobCoachProtocol = get().adviesJobCoachProtocol;
        console.log("q3C is " + q3C)
        console.log("adviesJobCoachProtocol is " + adviesJobCoachProtocol)
        if (q3A === true || q3B === true || q3C === true || q3D === true || q3E) {
            set({ nextResult: true })
        }
        else {
            set({ nextResult: false })
        }
    },
    addQ3D: () => {
        set(state => ({ q3D: !state.q3D }));
        set(state => ({ duurzaamheidMonitoringEnAdvies: state.q3D }))
        const q3A = get().q3A;
        const q3B = get().q3B;
        const q3C = get().q3C;
        const q3D = get().q3D;
        const q3E = get().q3E;
        const duurzaamheidMonitoringEnAdvies = get().duurzaamheidMonitoringEnAdvies;
        console.log("q3D is " + q3D)
        console.log("duurzaamheidMonitoringEnAdvies is " + duurzaamheidMonitoringEnAdvies)
        if (q3A === true || q3B === true || q3C === true || q3D === true || q3E) {
            set({ nextResult: true })
        }
        else {
            set({ nextResult: false })
        }
    },
    addQ3E: () => {
        set(state => ({ q3E: !state.q3E }));
        const q3A = get().q3A;
        const q3B = get().q3B;
        const q3C = get().q3C;
        const q3D = get().q3D;
        const q3E = get().q3E;
        console.log("q3E is " + q3E)
        if (q3A === true || q3B === true || q3C === true || q3D === true || q3E) {
            set({ nextResult: true })
        }
        else {
            set({ nextResult: false })
        }
    },

    //nav functions
    addStep2: () => {
        const next2 = get().next2;
        if (next2 === true) {
            set(state => ({ step: state.step + 1, progress: state.progress + 40 }))
            set({ next2Popup: false })
        }
        else {
            set({ next2Popup: true })
        }
    },
    addStep3: () => {
        const next3 = get().next3;
        if (next3 === true) {
            set(state => ({ step: state.step + 1, progress: state.progress + 40 }))
            set({ next3Popup: false })
        }
        else {
            set({ next3Popup: true })
        }
    },
    removeStep: () => set(state => ({ step: state.step - 1, progress: state.progress - 40 })),
    calcResultState: () => {
        const nextResult = get().nextResult;
        if (nextResult === true) {
            const moeilijkKwantificeerbareTaken = get().moeilijkKwantificeerbareTaken
            const verklaringEnVerantwoordingCompetenties = get().verklaringEnVerantwoordingCompetenties
            const adviesReintegratie = get().adviesReintegratie;
            const adviesJobCoachProtocol = get().adviesJobCoachProtocol;
            const duurzaamheidMonitoringEnAdvies = get().duurzaamheidMonitoringEnAdvies;

            if (moeilijkKwantificeerbareTaken === true || verklaringEnVerantwoordingCompetenties === true) {
                set({ pluz: true })
                set({ uniform: false })
                const pluz = get().pluz
                console.log("pluz is " + pluz)
            }
            if (adviesReintegratie === true || adviesJobCoachProtocol === true || duurzaamheidMonitoringEnAdvies === true) {
                set({ inclusief: true })
                set({ pluz: false })
                set({ uniform: false })
                const inclusief = get().inclusief
                console.log("inclusief is " + inclusief)
            }
            set({ result: true })
            set({ nextResultPopup: false })
        }
        else {
            set({ nextResultPopup: true })
        }
    },
    startNew: () => set({

         //interface vars
    step: 1,
    progress: 0,
    start: true,
    result: false,
    next2: false,
    next3: false,
    nextResult: false,
    next2Popup: false,
    next3Popup: false,
    nextResultPopup: false,

    //toelichting
    toelichtingInclusief: false,
    toelichtingPluz: false,
    toelichtingUniform: false,

    //loonwaarde vars
    uniformeMethodiek: true,
    caoTool: true,
    berekenenLKS: false,
    moeilijkKwantificeerbareTaken: false,
    verklaringEnVerantwoordingCompetenties: false,
    adviesReintegratie: false,
    adviesJobCoachProtocol: false,
    duurzaamheidMonitoringEnAdvies: false,

    //option vars
    q1A: false,
    q1B: false,
    q1C: false,
    q1D: false,

    q2A: false,
    q2B: false,

    q3A: false,
    q3B: false,
    q3C: false,
    q3D: false,
    q3E: false,


    //pakketten vars
    uniform: true,
    pluz: false,
    inclusief: false,
    }),
}))