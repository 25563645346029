import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import welkom from '../assets/images/welkom.png';
import { zustandStore } from '../store/states';

const StartInterface = () => {

    const startKiezer = zustandStore(state => state.startKiezer);
    return (
        <div>
            <Row className="mb-5">
                <Col>
                    <div className="d-flex justify-content-center align-items-center mb-5">
                        <Image height={'120vh'} src={welkom} />
                    </div>
                    <h2 className="start-text">Welkom bij de LoonwaardeKiezer</h2>
                    <p className="start-text">
                        Per 1 juli 2021 wordt loonwaarde door alle loonwaardesystemen op uniforme wijze vastgesteld. Dariuz ondersteunt de landelijke uniformering en voegt waarde toe.
                        Duurzaamheidsadvies, gebruikersvriendelijkheid en onze wetenschappelijke onderbouwing zijn daarbij leidend: modulair geïntegreerd, jij bepaalt zelf wat je nodig hebt.
                        Start de LoonwaardeKiezer om de opleiding te kiezen die aansluit bij de praktijk van jouw organisatie. Het kost je 1 minuut van jouw tijd.

                    </p>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                        <Button variant="primary" onClick={startKiezer}>Start <FontAwesomeIcon icon={faLongArrowAltRight} className="button-icon fa-lg" /></Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default StartInterface;