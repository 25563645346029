import React from 'react';
import { Row, Col, Card, Button, ListGroup, Modal, Fade, Accordion, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faLongArrowAltLeft, faInfoCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { zustandStore } from '../store/states';
import plusImage from '../assets/images/pluz.png';

const ResultaatInterface = () => {
    //loonwaarde vars
    const uniformeMethodiek = zustandStore(state => state.uniformeMethodiek);
    const caoTool = zustandStore(state => state.caoTool);
    const berekenenLKS = zustandStore(state => state.berekenenLKS);
    const moeilijkKwantificeerbareTaken = zustandStore(state => state.moeilijkKwantificeerbareTaken);
    const verklaringEnVerantwoordingCompetenties = zustandStore(state => state.verklaringEnVerantwoordingCompetenties);
    const adviesReintegratie = zustandStore(state => state.adviesReintegratie);
    const adviesJobCoachProtocol = zustandStore(state => state.adviesJobCoachProtocol);
    const duurzaamheidMonitoringEnAdvies = zustandStore(state => state.duurzaamheidMonitoringEnAdvies);
    const toelichtingUniformState = zustandStore(state => state.toelichtingUniformState)
    const toelichtingPluzState = zustandStore(state => state.toelichtingPluzState)
    const toelichtingInclusiefState = zustandStore(state => state.toelichtingInclusiefState)

    //pakketten vars
    const uniform = zustandStore(state => state.uniform);
    const pluz = zustandStore(state => state.pluz);
    const inclusief = zustandStore(state => state.inclusief);
    const toelichtingUniform = zustandStore(state => state.toelichtingUniform)
    const toelichtingPluz = zustandStore(state => state.toelichtingPluz)
    const toelichtingInclusief = zustandStore(state => state.toelichtingInclusief)

    const startNew = zustandStore(state => state.startNew)
    return (
        <Fade in appear>
            <div>
                <Row className="mb-5">
                    <Col xs={12}><h2>De opleiding die aansluit bij jouw praktijk is <span className="vraag-nummer">{uniform === true && pluz === false && inclusief === false ?
                        (<span>Dariuz Loonwaarde Uniform</span>) :
                        (<span>{uniform === true && pluz === true && inclusief === false ? (<span>Dariuz PluZ</span>) : (<span>Dariuz InclusieV</span>)}</span>)}
                    </span></h2></Col>
                    <Col xs={12} className="mb-3"><p><span className="NB">NB: </span>De opleidingen kennen een modulaire opbouw en gaan logisch in elkaar over. De opleiding Dariuz PluZ dient te worden voorafgegaan door Dariuz Loonwaarde Uniform. Dariuz InclusieV dient vooraf te worden gegaan door Dariuz PluZ.</p></Col>
                    <Col xs={12} lg={4}>
                        <Card className="result-box" style={uniform ? { borderColor: '#2db49b', backgroundColor: '#f0ebeb' } : { borderColor: '#f0ebeb' }}>
                            <ListGroup variant="flush">
                                <div className="d-flex justify-content-end mr-2 mt-2"><FontAwesomeIcon className="info fa-lg" icon={faInfoCircle} onClick={toelichtingUniformState} /></div>
                                <ListGroup.Item className="result-box__list"><h3 className="result-box__title" style={uniform ? { color: '#00419b' } : { color: '#141e3c' }}>Dariuz Loonwaarde Uniform</h3></ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{uniformeMethodiek ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Uniforme methodiek loonwaarde</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Methodiek loonwaarde die per 1 juli verplicht is bij toepassing LKS en Wajong</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{caoTool ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>CAO-Tool</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Meest onderzochte functies naar CAO, functiehandboek en gevraagde competenties</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{berekenenLKS ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Rekentool LKS</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Berekening loonkostensubsidie conform Besluit LKS Participatiewet</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Card className="result-box" style={pluz ? { borderColor: '#2db49b', backgroundColor: '#f0ebeb' } : { borderColor: '#f0ebeb' }}>
                            <ListGroup variant="flush">
                                <div className="d-flex justify-content-end mr-2 mt-2"><FontAwesomeIcon className="info fa-lg" icon={faInfoCircle} onClick={toelichtingPluzState} /></div>
                                <ListGroup.Item className="result-box__list"><h3 className="result-box__title" style={pluz ? { color: '#00419b' } : { color: '#141e3c' }}>Dariuz PluZ</h3></ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{moeilijkKwantificeerbareTaken ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Rekentool niet-kwantificeerbare taken</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Methodische rekenwijze voor taken die niet kunnen worden uitgedrukt in productie- of tijdseenheden</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{verklaringEnVerantwoordingCompetenties ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Verklaring &amp; verantwoording competenties</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Per taak wordt de arbeidsprestatie verklaard door de vergelijking van aanwezige competenties met gevraagde competenties</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4}>
                        <Card className="result-box" style={inclusief ? { borderColor: '#2db49b', backgroundColor: '#f0ebeb' } : { borderColor: '#f0ebeb' }}>
                            <ListGroup variant="flush">
                                <div className="d-flex justify-content-end mr-2 mt-2"><FontAwesomeIcon className="info fa-lg" icon={faInfoCircle} onClick={toelichtingInclusiefState} /></div>
                                <ListGroup.Item className="result-box__list"><h3 className="result-box__title" style={inclusief ? { color: '#00419b' } : { color: '#141e3c' }}>Dariuz InclusieV</h3></ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{adviesReintegratie ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Advies re-integratie</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Advies coachen werknemer en aanpassing werkplek om zo duurzaamheid plaatsing en individuele loonwaarde te bevorderen</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{adviesJobCoachProtocol ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Jobcoachprotocol</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Intensiteit en duur jobcoaching volgens Beleidsregels Jobcoachprotocol</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item className="result-box__list">
                                    <Row>
                                        <Col xs={1}>{duurzaamheidMonitoringEnAdvies ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</Col>
                                        <Col xs={10} className="dropDown__col">
                                            <Accordion className="dropDown mb-2">
                                                <Card className="dropDown__card__result">
                                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle__result">
                                                        <Row>
                                                            <Col xs={10}><p>Monitoring &amp; advies Duurzaamheid</p></Col><Col className="dropDown__button__container" xs={1}><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" /></Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                                        <Card.Body className="dropDown__body">
                                                            <Col xs={10} className="dropDown__body"><p className="dropDown__body__text">Scant 5 indicatoren: signaleert en verstrekt advies</p></Col>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col xs={12} className="mt-3"><p><FontAwesomeIcon icon={faCheckCircle} className="check" /> = de door jou aangevinkte opties in de LoonwaardeKiezer</p></Col>
                    <Col xs={12} className="mt-1"><p><FontAwesomeIcon icon={faChevronDown} className="dropDown__button fa-sm mr-2" />= klik hierop voor meer inhoudelijke informatie</p></Col>
                </Row>

                <Row className="mb-5">
                    <Col xs={12} lg={3} className="mb-2"><Button variant="primary" href="mailto:info@dariuz.nl?subject= Neem contact op voor meer informatie over de resultaten uit de LoonwaardeKiezer"><FontAwesomeIcon icon={faEnvelope} className="button-icon" /> Neem contact op</Button></Col>
                    <Col xs={12} lg={9} className="d-flex  align-items-center"><p style={{ fontWeight: 'bold' }}>info@dariuz.nl</p></Col>
                </Row>
                <Row>
                    <Col><Button variant="link" onClick={startNew}><FontAwesomeIcon icon={faLongArrowAltLeft} className="link-icon fa-lg" /> Vul opnieuw in</Button></Col>
                </Row>
                <Modal size="xl" show={toelichtingUniform} onHide={toelichtingUniformState} className="infoModal">
                    <Modal.Header closeButton className="infoModal__header">
                        <Modal.Title className="infoModal__title"><h3 className="result-box__title">Toelichting <span className="vraag-nummer">Dariuz Loonwaarde Uniform</span></h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="infoModal__body">
                        <ul>
                            <li>Opleiding tot geregistreerd loonwaarde-deskundige</li>
                            <li>Methodiek met focus op de output: compact</li>
                            <li>Landelijk uniform rapport: compact</li>
                            <li>Praktische toelichting op de arbeidsprestatie: compact</li>
                            <li>Efficiënte gedigitaliseerde rapportage</li>
                            <li>Gedigitaliseerde CAO-tool om snel tot de juiste berekening te komen</li>
                            <li>Gedigitaliseerde berekening van de Loonkostensubsidie</li>
                            <li>Totale studielast: 6 dagen, waarvan 2 dagen training. </li>
                        </ul>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={toelichtingPluz} onHide={toelichtingPluzState} className="infoModal">
                    <Modal.Header closeButton className="infoModal__header">
                        <Modal.Title className="infoModal__title"><h3 className="result-box__title">Toelichting<span className="vraag-nummer"> Dariuz Pluz</span></h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="infoModal__body">
                        <Accordion className="dropDown mb-3">
                            <Card className="dropDown__card">
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle">
                                    <Row>
                                        <Col xs={12}><p className="dropDown__title">Basis Loonwaarde Uniform <FontAwesomeIcon icon={faInfoCircle} className="info fa-lg mr-2" /></p></Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                    <Card.Body>
                                        <ul>
                                            <li>Opleiding tot geregistreerd loonwaarde-deskundige</li>
                                            <li>Methodiek met focus op de output: compact</li>
                                            <li>Landelijk uniform rapport: compact</li>
                                            <li>Praktische toelichting op de arbeidsprestatie: compact</li>
                                            <li>Efficiënte gedigitaliseerde rapportage</li>
                                            <li>Gedigitaliseerde CAO-tool om snel tot de juiste berekening te komen</li>
                                            <li>Gedigitaliseerde berekening van de Loonkostensubsidie</li>
                                            <li>Totale studielast: 6 dagen, waarvan 2 dagen training. </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Image src={plusImage} className="pluz-image" />
                        <ul>
                            <li>Rekent met output en verklaart op basis van competenties:  the best of both worlds</li>
                            <li>Rapport:
                                <ul>
                                    <li>Verklaart op zeer inzichtelijke wijze de arbeidsprestatie</li>
                                    <li>Verantwoordt de gekozen normfunctie</li>
                                    <li>De bevindingen zijn toetsbaar, inzichtelijk en te herleiden: SRA-proof. </li>
                                </ul>
                            </li>
                            <li>Rekenmodule voor niet of lastig te kwantificeren taken </li>
                            <li>Genereert tekstdelen voor het verklaren van de arbeidsprestatie</li>
                            <li>Rekentool voor loonwaarde in euro’s, nodig voor Wajong en private markt</li>
                            <li>De loonwaarde-deskundige bepaalt de wijze van rapportage: compact of meer onderbouwd</li>
                            <li>Totale studielast: 1,5 dagen, waarvan 1 dag training </li>
                        </ul>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={toelichtingInclusief} onHide={toelichtingInclusiefState} className="infoModal">
                    <Modal.Header closeButton className="infoModal__header">
                        <Modal.Title className="infoModal__title"><h3 className="result-box__title">Toelichting<span className="vraag-nummer"> Dariuz InclusieV</span></h3></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="infoModal__body">
                        <Accordion className="dropDown mb-3">
                            <Card className="dropDown__card">
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle">
                                    <Row>
                                        <Col xs={12}><p className="dropDown__title">Basis Loonwaarde Uniform <FontAwesomeIcon icon={faInfoCircle} className="info fa-lg mr-2" /></p></Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                    <Card.Body>
                                        <ul>
                                            <li>Opleiding tot geregistreerd loonwaarde-deskundige</li>
                                            <li>Methodiek met focus op de output: compact</li>
                                            <li>Landelijk uniform rapport: compact</li>
                                            <li>Praktische toelichting op de arbeidsprestatie: compact</li>
                                            <li>Efficiënte gedigitaliseerde rapportage</li>
                                            <li>Gedigitaliseerde CAO-tool om snel tot de juiste berekening te komen</li>
                                            <li>Gedigitaliseerde berekening van de Loonkostensubsidie</li>
                                            <li>Totale studielast: 6 dagen, waarvan 2 dagen training. </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Image src={plusImage} className="pluz-image" />
                        <Accordion className="dropDown mb-3">
                            <Card className="dropDown__card">
                                <Accordion.Toggle as={Card.Header} eventKey="0" className="dropDown__toggle">
                                    <Row>
                                        <Col xs={12}><p className="dropDown__title">Dariuz PluZ <FontAwesomeIcon icon={faInfoCircle} className="info fa-lg mr-2" /></p></Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className="dropDown__collapse">
                                    <Card.Body>
                                        <ul>
                                            <li>Rekent met output en verklaart op basis van competenties:  the best of both worlds</li>
                                            <li>Rapport:
                                                <ul>
                                                    <li>Verklaart op zeer inzichtelijke wijze de arbeidsprestatie</li>
                                                    <li>Verantwoordt de gekozen normfunctie</li>
                                                    <li>De bevindingen zijn toetsbaar, inzichtelijk en te herleiden: SRA-proof. </li>
                                                </ul>
                                            </li>
                                            <li>Rekenmodule voor niet of lastig te kwantificeren taken </li>
                                            <li>Genereert tekstdelen voor het verklaren van de arbeidsprestatie</li>
                                            <li>Rekentool voor loonwaarde in euro’s, nodig voor Wajong en private markt</li>
                                            <li>De loonwaarde-deskundige bepaalt de wijze van rapportage: compact of meer onderbouwd</li>
                                            <li>Totale studielast: 1,5 dagen, waarvan 1 dag training </li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <Image src={plusImage} className="pluz-image" />
                        <ul>
                            <li>Inzichtelijk onderbouwd advies in het kader van re-integratie en duurzaamheid</li>
                            <li>Helder advies hoe jobcoaching vorm te geven volgens regels jobcoachingprotocol</li>
                            <li>Rapport:
                                <ul>
                                    <li>Verklaart op inzichtelijke wijze de arbeidsprestatie</li>
                                    <li>Adviseert interventies per taak</li>
                                    <li>Adviseert interventies voor duurzaam werken</li>
                                </ul>
                            </li>
                            <li>De bevindingen zijn toetsbaar, inzichtelijk en te herleiden: SRA-proof</li>
                            <li>Genereert tekstdelen voor advies:
                            <ul>
                                    <li>Interventies werknemer</li>
                                    <li>Geschikt maken werkomgeving</li>
                                </ul>
                            </li>
                            <li>Advies duurzaamheid op basis van 5 indicatoren</li>
                            <li>De loonwaarde-expert bepaalt de wijze van rapportage: compact of meer onderbouwd. </li>
                            <li>Totale studielast: 1 dag training</li>
                        </ul>
                    </Modal.Body>
                </Modal>
            </div>
        </Fade>
    )
};

export default ResultaatInterface;