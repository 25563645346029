import React from 'react';
import { Button, ProgressBar, Row, Col, Image, Collapse, Fade } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { zustandStore } from '../store/states';
import vraag1 from '../assets/images/vraag1.png';
import vraag2 from '../assets/images/vraag2.png';
import vraag3 from '../assets/images/vraag3.png';

const VragenInterface = ({ calcResult }) => {
    const step = zustandStore(state => state.step)
    const progress = zustandStore(state => state.progress)
    const nextStep2 = zustandStore(state => state.addStep2)
    const nextStep3 = zustandStore(state => state.addStep3)
    const prevStep = zustandStore(state => state.removeStep)

    //voor als iemand de vroeg naar volgende wil
    const next2Popup = zustandStore(state => state.next2Popup)
    const next3Popup = zustandStore(state => state.next3Popup)
    const nextResultPopup = zustandStore(state => state.nextResultPopup)

    const q1A = zustandStore(state => state.q1A)
    const q1B = zustandStore(state => state.q1B)
    const q1C = zustandStore(state => state.q1C)
    const q1D = zustandStore(state => state.q1D)

    const q2A = zustandStore(state => state.q2A)
    const q2B = zustandStore(state => state.q2B)

    const q3A = zustandStore(state => state.q3A)
    const q3B = zustandStore(state => state.q3B)
    const q3C = zustandStore(state => state.q3C)
    const q3D = zustandStore(state => state.q3D)
    const q3E = zustandStore(state => state.q3E)

    const addQ1A = zustandStore(state => state.addQ1A)
    const addQ1B = zustandStore(state => state.addQ1B)
    const addQ1C = zustandStore(state => state.addQ1C)
    const addQ1D = zustandStore(state => state.addQ1D)

    const addQ2A = zustandStore(state => state.addQ2A)
    const addQ2B = zustandStore(state => state.addQ2B)

    const addQ3A = zustandStore(state => state.addQ3A)
    const addQ3B = zustandStore(state => state.addQ3B)
    const addQ3C = zustandStore(state => state.addQ3C)
    const addQ3D = zustandStore(state => state.addQ3D)
    const addQ3E = zustandStore(state => state.addQ3E)

    return (
        <Row className="vragen-interface">
            <Col md={5} className="d-flex justify-content-center">
                {step === 1 ? (
                    <Fade in appear>
                    <Image className="vragen-interface__image" src={vraag1} />
                    </Fade>
                ) : (
                    <div>
                        {step === 2 ? (
                            <Fade in appear>
                            <Image className="vragen-interface__image" src={vraag2} />
                            </Fade>
                        ) :
                            (
                                <Fade in appear>
                            <Image className="vragen-interface__image" src={vraag3} />
                            </Fade>
                            )}
                    </div>
                )}
            </Col>
            <Col md={7}>
                <h2>Vraag <span className="vraag-nummer">{step}</span></h2>
                <ProgressBar now={progress} />
                {step === 1 ? (
                    <Fade in appear>
                    <div className="vragen-interface__vragen">
                        <p className="mb-3">Ik pas loonwaarde toe in het kader van: <span className="sub-text">(Meerdere antwoorden mogelijk)</span></p>
                        <Button variant="secondary" block onClick={addQ1A}>
                            <Row><Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q1A ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col><Col xs={10}>Loonkostensubsidie (Participatiewet)</Col></Row>
                        </Button>
                        <Button variant="secondary" block onClick={addQ1B}>
                            <Row><Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q1B ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col><Col xs={10}>WSW</Col></Row>
                        </Button>
                        <Button variant="secondary" block onClick={addQ1C}>
                            <Row><Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q1C ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                <Col xs={10}>Loondispensatie (Wajong)
                            </Col></Row>
                        </Button>
                        <Button variant="secondary" block onClick={addQ1D}>
                            <Row><Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q1D ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col><Col xs={10}>Wet verbetering Poortwachter</Col></Row>
                        </Button>
                        <Collapse in={q1C || q1D}>
                            <div className="mt-3">
                                <h4>Opmerking:</h4>
                                <p>De richtlijnen voor een arbeidsdeskundige (SRA) stellen hoge eisen aan het onderzoek en de  verantwoording ervan in de rapportage. Daarom adviseren wij voor arbeidsdeskundigen de modules Dariuz PluZ of Dariuz InclusieV.</p>
                            </div>
                        </Collapse>
                    </div>
                    </Fade>
                ) : (
                    <div className="vragen-interface__vragen">
                        {step === 2 ? (
                            <Fade in appear>
                            <div>
                                <p className="mb-3">De uniforme methode bepaalt loonwaarde op basis van enkel tijdseenheden of productie-aantallen. In bijna 50% van de onderzoeken is dit echter lastig of niet mogelijk omdat bijvoorbeeld sociale vaardigheden het resultaat bepalen en die zijn niet uit te drukken in tijdseenheden of productie-aantallen; denk hierbij aan een gastvrouw in een verzorgingstehuis, haar glimlach kan belangrijker zijn dan de kopjes koffie die ze schenkt.</p>
                                <Button variant="secondary" block onClick={addQ2A}>
                                    <Row>
                                        <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q2A ? (<FontAwesomeIcon icon={faCircle} />) : (<div />)}</div></Col>
                                        <Col xs={10}>Ik kom in mijn praktijk regelmatig functies tegen waarbij sprake is van taken die niet of niet makkelijk in eenheden of aantallen kunnen worden uitgedrukt</Col>
                                    </Row>
                                </Button>
                                <Button variant="secondary" block onClick={addQ2B}>
                                    <Row>
                                        <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q2B ? (<FontAwesomeIcon icon={faCircle} />) : (<div />)}</div></Col>
                                        <Col xs={10}>Ik kom in mijn praktijk zeer zelden functies tegen waarbij sprake is van taken die niet of niet makkelijk in eenheden of aantallen kunnen worden uitgedrukt</Col>
                                    </Row>
                                </Button>
                            </div>
                            </Fade>
                        ) : (
                            <div className="vragen-interface__vragen">
                                {step === 3 ? (
                                    <Fade in appear>
                                    <div>
                                        <p className="mb-3">Vink aan wat van toepassing is: <span className="sub-text">(Meerdere antwoorden mogelijk)</span></p>
                                        <Button variant="secondary" block onClick={addQ3A}>
                                            <Row>
                                                <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q3A ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                                <Col xs={10}>Ik wil inzicht verkrijgen in de wijze waarop de arbeidsprestatie tot stand is gekomen</Col>
                                            </Row>
                                        </Button>
                                        <Button variant="secondary" block onClick={addQ3B}>
                                            <Row>
                                                <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q3B ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                                <Col xs={10}>Ik wil advies kunnen geven voor re-integratie</Col>
                                            </Row>
                                        </Button>
                                        <Button variant="secondary" block onClick={addQ3C}>
                                            <Row>
                                                <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q3C ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                                <Col xs={10}>Ik wil advies kunnen geven voor jobcoaching naar duur en intensiteit (jobcoachprotocol)</Col>
                                            </Row>
                                        </Button>
                                        <Button variant="secondary" block onClick={addQ3D}>
                                            <Row>
                                                <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q3D ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                                <Col xs={10}>Ik wil weten of de plaatsing duurzaam is en tips hoe uitval te voorkomen</Col>
                                            </Row>
                                        </Button>
                                        <Button variant="secondary" block onClick={addQ3E}>
                                            <Row>
                                                <Col xs={2}><div className="check__border d-flex justify-content-center align-items-center">{q3E ? (<FontAwesomeIcon icon={faCheckCircle} className="check" />) : (<div />)}</div></Col>
                                                <Col xs={10}>Ik wil loonwaarde alleen toepassen om de beschikking van de loonkostensubsidie te onderbouwen</Col>
                                            </Row>
                                        </Button>
                                    </div>
                                    </Fade>
                                ) : (
                                    <div>
                                    </div>
                                )
                                }
                            </div>
                        )}
                    </div>
                )}
                {step === 1 ? (
                    <Fade in appear>
                    <Row>
                        <Col xs={6} md={6} lg={8}  ></Col>
                        <Col md={6} lg={4} className="d-flex justify-content-end flex-column"><Button variant="primary" onClick={nextStep2}>Volgende</Button>
                        <Collapse in={next2Popup}><div className="mt-2"><p className="warn-text">Maak een keuze</p></div></Collapse></Col>
                    </Row>
                    </Fade>
                ) : (
                    <div>
                        {step === 2 ? (
                            <Fade in appear>
                            <Row>
                                <Col xs={6} md={6} lg={8}   ><Button variant="link" onClick={prevStep}><FontAwesomeIcon icon={faLongArrowAltLeft} className="link-icon fa-lg" /> Vorige</Button></Col>
                                <Col  md={6} lg={4} className="d-flex justify-content-end flex-column"><Button variant="primary" onClick={nextStep3}>Volgende</Button>
                                <Collapse in={next3Popup}><div className="mt-2"><p className="warn-text">Maak een keuze</p></div></Collapse>
                                </Col>
                            </Row>
                            </Fade>
                        ) : (
                            <div>
                                {step === 3 ? (
                                    <Fade in appear>
                                    <Row>
                                        <Col xs={6} md={6} lg={8}  ><Button variant="link" onClick={prevStep}><FontAwesomeIcon icon={faLongArrowAltLeft} className="link-icon fa-lg" /> Vorige</Button></Col>
                                        <Col  md={6} lg={4} className="d-flex justify-content-end flex-column"><Button variant="primary" onClick={calcResult}>Uitslag</Button>
                                        <Collapse in={nextResultPopup}><div className="mt-2"><p className="warn-text">Maak een keuze</p></div></Collapse></Col>
                                    </Row>
                                    </Fade>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Col>
        </Row>
    )
};

export default VragenInterface;