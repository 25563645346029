import './styling/App.scss';
import LoonwaardeKiezer from './pages/LoonwaardeKiezer';

const App = () => {
  return (
    <div className="App">
          <LoonwaardeKiezer />
    </div>

  );
}

export default App;
